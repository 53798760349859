import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import CheckAuth from '../hooks/checkAuth';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is correctly imported if you're using it
import '../css/login.css';
import '../css/home.css'
import { useAuth } from '../context/AuthContext';
import HomepageMenu from '../components/HomepageMenu';
import Footer from '../components/Footer';
import firstImage from '../images/login.png'
import { Circles } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { setOnBoarded } from "../redux/slices/onboardSlice";
import { useTranslation } from 'react-i18next';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '' });
    const [incorrectCredentials, setIncorrectCredentials] = useState(false);
    const [accountDisabled, setAccountDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const { login } = useAuth();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const validateEmail = () => {
        let tempErrors = { email: '' };
        let formIsValid = true;

        // Simple email validation
        if (!email) {
        formIsValid = false;
        tempErrors['email'] = 'Email is required';
        }

        setErrors(tempErrors);
        return formIsValid;
    }

    const validatePassword = () => {
        let tempErrors = { password: '' };
        let formIsValid = true;

        // Simple password validation
        if (!password) {
            formIsValid = false;
            tempErrors['password'] = 'Password is required';
            }

        setErrors(tempErrors);
        return formIsValid;
    }

    const validateForm = () => {
        return validateEmail() && validatePassword();
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/checkUserCredentials`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({email, password})
                });
    
                if (!response.ok) {
                    setLoading(false);
                    if (response.status === 404 || response.status === 401 || response.status === 400) {
                        setIncorrectCredentials(true);
                        setAccountDisabled(false);
                    } else if (response.status === 409) {
                        setAccountDisabled(true);
                        setIncorrectCredentials(false);
                    }else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                } else {
                    const data = await response.json();
                    localStorage.setItem('userId', data.user);
                    localStorage.setItem('businessName', data.businessName);
                    localStorage.setItem('accessToken', data.accessToken);
                    localStorage.setItem('verified', data.verified);
                    dispatch(setOnBoarded(data.onBoarded));
                    setLoading(false);
                    if(data.verified)
                    {
                        login('user');
                        navigate('/dashboard');
                    }
                    else
                        navigate('/verification');
                }
                
            } catch (error) {
                setLoading(false);
                console.error('Failed to fetch:', error);
            }
        }
    };
    

    return (
        <>
        <HomepageMenu />
        <Helmet>
                <title>{t('login')}</title>
            <meta name="description" content="Log in to your Flexmerce account to access powerful tools for automating invoicing, reducing marketplace fees, and enhancing customer service. Manage your account and explore solutions tailored for online sellers on platforms like Backmarket and Refurbed." />
        </Helmet>
        <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        >
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
            <div className="container text-center">
                <div className='row'>
                    <div className='col-sm-5'>
                        <div className="new-login-card mt-5">
                            <h2 className=' fw-bold'>{t('log')}</h2>
                            <hr className='m-2'></hr>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    onBlur={validateEmail}
                                    className={`form-control p-4 ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder=""
                                    autoFocus
                                    required
                                    />
                                    <label className="floating-label-register">{t('emailAddress')}</label>
                                </div>
                                <div className="form-group">
                                    <input
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    onBlur={validatePassword}
                                    className={`form-control p-4 ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder=""
                                    required
                                    />
                                    <label className="floating-label-register">{t('password')}</label>
                                </div>
                            
                                {incorrectCredentials && (
                                    <div className="alert alert-danger p-2 text-center justify-content-center" role="alert">
                                    {t('incorrectCredentials')}
                                    </div>
                                )}

                                {accountDisabled && (
                                    <div className="alert alert-danger p-2 text-center justify-content-center" role="alert">
                                    {t('restrictedAccount')}
                                    </div>
                                )}

                                <div className="form-group">
                                    <button type="submit" className="btn btn-dark w-100 p-3 bg-blue">{t('login')}</button>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <a href="/forgotPassword" className="font-orange font-1rem">{t('forgotPassword')}</a>
                                        <a href="/registerbusiness" className="font-orange font-1rem">{t('registerHere')}</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-sm-7 landing-container-image services-first-image-div'>
                        <img src={firstImage} alt='autopilot' className='services-first-image' />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    )
}