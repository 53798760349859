import React, { useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useParams } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Dashboard from './pages/Dashboard';
import NewInvoice from './pages/NewInvoice';
import Customers from './pages/Customers';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import PrintInvoice from './pages/PrintInvoice';
import Logout from './pages/logout';
import NoPage from './pages/NoPage';
import ResetPassword from './pages/ResetPassword';
import RegisterBusiness from './pages/RegisterBusiness';
import AdminLogin from './pages/AdminLogin';
import AddUpdateTaxOption from './pages/AddUpdateTaxOption';
import ManageTaxOptions from './pages/ManageTaxOptions';
import ExportInvoices from './pages/ExportInvoices';
import PrintMultipleInvoices from './pages/PrintMultipleInvoices';
import Services from './pages/Services';
import Appointment from './pages/Appointment';
import CalculateSavings from './pages/CalculateSavings';
import JobHistories from './pages/JobHistories';
import Pricing from './pages/Pricing';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import SendCustomEmail from './pages/SendCustomEmail';
import EmailVerification from './pages/EmailVerification';
import Invoices from './pages/Invoices';
import Guide from './pages/Guide';
import BackMarket from './pages/BackMarket';
import Refurbed from './pages/Refurbed';

import { AuthProvider, useAuth } from './context/AuthContext';
import Blog from './pages/Blog'; // Your dynamic blog page component
import BlogList from './pages/BlogList';
import store from './redux/store/store';
import { Provider, useSelector } from 'react-redux';
import BusinessDetails from './pages/BusinessDetails';
import InvoiceTemplate from './pages/InvoiceTemplate';
import Integrations from './pages/Integrations';
import { useDispatch } from 'react-redux';
import { syncProfileSetup } from './redux/slices/profileSlice';
import { syncTemplate } from './redux/slices/invoiceTemplateSlice';
import { syncIntegration } from './redux/slices/integrationSlice';
import { syncTax } from './redux/slices/taxSlice';
import { syncOnBoarded } from './redux/slices/onboardSlice';
import { useTranslation } from 'react-i18next';
import { setLanguage, syncLanguage } from './redux/slices/languageSlice';

const AppContent = () => {
  const location = useLocation(); 
  const { isAuthenticated } = useAuth(); // Now inside the context of AuthProvider
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const supportedLanguages = ['en', 'es', 'fr', 'de', 'it', 'dk', 'pl', 'pt', 'se', 'sk', 'nl', 'us'];
  const currentLanguageCode = useSelector((state) => state.onlanguage.currentLanguageCode);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'isProfileSetup') {
        dispatch(syncProfileSetup());
      } else if (event.key === 'isTemplateSetup') {
        dispatch(syncTemplate());
      } else if (event.key === 'isIntegrationSetup') {
        dispatch(syncIntegration());
      } else if (event.key === 'isTaxSetup') {
        dispatch(syncTax());
      } else if (event.key === 'isOnBoarded') {
        dispatch(syncOnBoarded());
      } else if (event.key === 'currentLanguageCode') {
        dispatch(syncLanguage());
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  const navbarRoutes = [
    '/dashboard',
    '/reports',
    '/settings',
    '/customers',
    '/invoices',
    '/invoice/new',
    '/invoice/edit/:invoiceId',
    '/exportInvoices',
    '/jobHistories',
    '/manageTaxOptions',
    '/addUpdateTaxOption/new',
    '/addUpdateTaxOption/edit/:taxOptionId',
    '/settings/businessDetails',
    '/settings/invoiceTemplate',
    '/settings/integrations'
  ];

  const showNavbar = isAuthenticated && navbarRoutes.some((route) => {
    const regex = new RegExp(`^/${currentLanguageCode}${route.replace(':invoiceId', '[a-zA-Z0-9]+').replace(':taxOptionId', '[a-zA-Z0-9]+')}$`);
    return regex.test(location.pathname);
  });

  const setLanguageFromPath = (pathname) => {
    const langCode = pathname.split('/')[1];
    if (['en', 'es', 'fr', 'de', 'it', 'dk', 'pl', 'pt', 'se', 'sk', 'nl', 'us'].includes(langCode)) {
      i18n.changeLanguage(langCode);
    } else {
      i18n.changeLanguage(currentLanguageCode);
    }
  };

  useEffect(() => {
    setLanguageFromPath(location.pathname);
  }, [location.pathname]);

  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
        <Route path="/" element={<Navigate to={`/${currentLanguageCode}/home`} replace />} />

        {supportedLanguages.map((lang) => (
          <React.Fragment key={lang}>
            <Route path={`/${lang}`} element={<Home />} />
            <Route path={`/${lang}/home`} element={<Home />} />
            <Route path={`/${lang}/contact`} element={<Contact />} />
            <Route
              path={`/${lang}/login`}
              element={
                isAuthenticated ? (
                  <Navigate to={`/${lang}/dashboard`} replace />
                ) : (
                  <Login />
                )
              }
            />
            <Route path={`/${lang}/forgotPassword`} element={<ForgotPassword />} />
            <Route path={`/${lang}/dashboard`} element={<Dashboard />} />
            <Route path={`/${lang}/invoice/new`} element={<NewInvoice />} />
            <Route path={`/${lang}/invoice/edit/:invoiceId`} element={<NewInvoice />} />
            <Route path={`/${lang}/customers`} element={<Customers />} />
            <Route path={`/${lang}/reports`} element={<Reports />} />
            <Route path={`/${lang}/settings`} element={<Settings />} />
            <Route path={`/${lang}/printInvoice/:invoiceId`} element={<PrintInvoice />} />
            <Route path={`/${lang}/logout`} element={<Logout />} />
            <Route path={`/${lang}/verification/:token`} element={<ResetPassword />} />
            <Route
              path={`/${lang}/registerbusiness`}
              element={
                isAuthenticated ? (
                  <Navigate to={`/${lang}/dashboard`} replace />
                ) : (
                  <RegisterBusiness />
                )
              }
            />
            <Route path={`/${lang}/adminlogin`} element={<AdminLogin />} />
            <Route path={`/${lang}/manageTaxOptions`} element={<ManageTaxOptions />} />
            <Route path={`/${lang}/addUpdateTaxOption/new`} element={<AddUpdateTaxOption />} />
            <Route path={`/${lang}/addUpdateTaxOption/edit/:taxOptionId`} element={<AddUpdateTaxOption />} />
            <Route path={`/${lang}/exportInvoices`} element={<ExportInvoices />} />
            <Route path={`/${lang}/printMultipleInvoices`} element={<PrintMultipleInvoices />} />
            <Route path={`/${lang}/services`} element={<Services />} />
            <Route path={`/${lang}/appointment`} element={<Appointment />} />
            <Route path={`/${lang}/calculateSavings`} element={<CalculateSavings />} />
            <Route path={`/${lang}/jobHistories`} element={<JobHistories />} />
            <Route path={`/${lang}/pricing`} element={<Pricing />} />
            <Route path={`/${lang}/terms`} element={<Terms />} />
            <Route path={`/${lang}/privacy`} element={<Privacy />} />
            <Route path={`/${lang}/verification`} element={<EmailVerification />} />
            <Route path={`/${lang}/sendCustomEmail`} element={<SendCustomEmail />} />
            <Route path={`/${lang}/invoices`} element={<Invoices />} />
            <Route path={`/${lang}/guide`} element={<Guide />} />
            <Route path={`/${lang}/settings/businessDetails`} element={<BusinessDetails />} />
            <Route path={`/${lang}/settings/invoiceTemplate`} element={<InvoiceTemplate />} />
            <Route path={`/${lang}/settings/integrations`} element={<Integrations />} />
            {/* <Route path={`/${lang}/backmarket`} element={<BackMarket />} /> */}
            <Route path={`/${lang}/refurbed`} element={<Refurbed />} />
            {/* <Route path={`/${lang}/blogs`} element={<BlogList />} />
            <Route path={`/${lang}/blogs/:slug`} element={<Blog />} /> */}

            //Default Routes
            <Route path="/home" element={<Navigate to={`/${currentLanguageCode}/home`} replace />} />
            <Route path="/login" element={<Navigate to={`/${currentLanguageCode}/login`} replace />} />
            <Route path="/contact" element={<Navigate to={`/${currentLanguageCode}/contact`} replace />} />
            <Route path="/forgotPassword" element={<Navigate to={`/${currentLanguageCode}/forgotPassword`} replace />} />
            <Route path="/dashboard" element={<Navigate to={`/${currentLanguageCode}/dashboard`} replace />} />
            <Route path="/invoice/new" element={<Navigate to={`/${currentLanguageCode}/invoice/new`} replace />} />
            <Route path="/customers" element={<Navigate to={`/${currentLanguageCode}/customers`} replace />} />
            <Route path="/reports" element={<Navigate to={`/${currentLanguageCode}/reports`} replace />} />
            <Route path="/settings" element={<Navigate to={`/${currentLanguageCode}/settings`} replace />} />
            <Route path="/logout" element={<Navigate to={`/${currentLanguageCode}/logout`} replace />} />
            <Route path="/verification/:token" element={<Navigate to={`/${currentLanguageCode}/verification/:token`} replace />} />
            <Route path="/registerbusiness" element={<Navigate to={`/${currentLanguageCode}/registerbusiness`} replace />} />
            <Route path="/adminlogin" element={<Navigate to={`/${currentLanguageCode}/adminlogin`} replace />} />
            <Route path="/manageTaxOptions" element={<Navigate to={`/${currentLanguageCode}/manageTaxOptions`} replace />} />
            <Route path="/addUpdateTaxOption/new" element={<Navigate to={`/${currentLanguageCode}/addUpdateTaxOption/new`} replace />} />
            <Route path="/exportInvoices" element={<Navigate to={`/${currentLanguageCode}/exportInvoices`} replace />} />
            <Route path="/printMultipleInvoices" element={<Navigate to={`/${currentLanguageCode}/printMultipleInvoices`} replace />} />
            <Route path="/services" element={<Navigate to={`/${currentLanguageCode}/services`} replace />} />
            <Route path="/appointment" element={<Navigate to={`/${currentLanguageCode}/appointment`} replace />} />
            <Route path="/calculateSavings" element={<Navigate to={`/${currentLanguageCode}/calculateSavings`} replace />} />
            <Route path="/jobHistories" element={<Navigate to={`/${currentLanguageCode}/jobHistories`} replace />} />
            <Route path="/pricing" element={<Navigate to={`/${currentLanguageCode}/pricing`} replace />} />
            <Route path="/terms" element={<Navigate to={`/${currentLanguageCode}/terms`} replace />} />
            <Route path="/privacy" element={<Navigate to={`/${currentLanguageCode}/privacy`} replace />} />
            <Route path="/verification" element={<Navigate to={`/${currentLanguageCode}/verification`} replace />} />
            <Route path="/sendCustomEmail" element={<Navigate to={`/${currentLanguageCode}/sendCustomEmail`} replace />} />
            <Route path="/invoices" element={<Navigate to={`/${currentLanguageCode}/invoices`} replace />} />
            <Route path="/guide" element={<Navigate to={`/${currentLanguageCode}/guide`} replace />} />
            <Route path="/settings/businessDetails" element={<Navigate to={`/${currentLanguageCode}/settings/businessDetails`} replace />} />
            <Route path="/settings/invoiceTemplate" element={<Navigate to={`/${currentLanguageCode}/settings/invoiceTemplate`} replace />} />
            <Route path="/settings/integrations" element={<Navigate to={`/${currentLanguageCode}/settings/integrations`} replace />} />
            {/* <Route path="/backmarket" element={<Navigate to={`/${currentLanguageCode}/backmarket`} replace />} /> */}
            <Route path="/refurbed" element={<Navigate to={`/${currentLanguageCode}/refurbed`} replace />} />
            {/* <Route path="/blogs" element={<Navigate to={`/${currentLanguageCode}/blogs`} replace />} />
            <Route path="/blogs/:slug" element={<Navigate to={`/${currentLanguageCode}/blogs/:slug`} replace />} /> */}

          </React.Fragment>
        ))}

        {/* Catch-all for invalid routes */}
        <Route path="*" element={<NoPage />} />
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <AuthProvider> {/* Wrap the entire app in AuthProvider */}
      <Provider store={store}>
      <Router> 
          <AppContent /> {/* Content component now uses useAuth correctly */}
      </Router>
      </Provider>
    </AuthProvider>
  );
};

export default App;