import React, { useEffect, useState } from 'react';
import '../css/homepageMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function HomepageMenu() {
    const [menuOpen, setMenuOpen] = useState(false);
    const currentLanguageCode = useSelector((state) => state.onlanguage.currentLanguageCode);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    
      useEffect(() => {
          i18next.changeLanguage(currentLanguageCode);
      }, [currentLanguageCode])

      const { t } = useTranslation();

      return (
        <div className='menu-container'>
        <div className={`logged-off-navbar glass-effect ${menuOpen ? 'menu-open' : ''}`}>
            <a href="/" className="logo-link"><div className="logged-off-logo">FLEXMERCE</div></a>
            <div className="menu-icon" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <ul className={`menu-items ${menuOpen ? 'open' : ''}`}>
                <a href="/services"><li>{t('loggedOffMenu.services')}</li></a>
                <a href="/calculateSavings"><li>{t('loggedOffMenu.calculateSavings')}</li></a>
                <a href="/pricing"><li>{t('loggedOffMenu.pricing')}</li></a>
                <a href="/guide"><li>{t('guide')}</li></a>
                <a href="/appointment"><li>{t('loggedOffMenu.bookADemo')}</li></a>
                <a href="/contact"><li>{t('loggedOffMenu.contact')}</li></a>
                <a href="/registerbusiness"><li>{t('loggedOffMenu.signUp')}</li></a>
                <a href="/login"><li className="btn-login-homepage">{t('loggedOffMenu.login')}</li></a>
            </ul>
        </div>
        </div>
    );
}
