import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/appointment.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import { InlineWidget } from "react-calendly";
import guideVideo from '../images/Guide-video.mp4'
import { useTranslation } from 'react-i18next';

export default function Appointment() {
  const [menuHeight, setMenuHeight] = useState(0);
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);
  const { t } = useTranslation();

  return (
    <>
    <HomepageMenu />
    <Helmet>
        <title>{ t('bookDemo')}</title>
      <meta name="description" content="Schedule a personalized demo with Flexmerce to explore how our solutions can automate your customer service, reduce marketplace fees, and streamline operations. Book a demo appointment today to see our platform in action and discover the benefits for your business." />
    </Helmet>
    <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
    >
      <div className='container'>
      <h1 className='mt-3 font-orange text-center appointment-title mt-2 mb-3'>{ t('bookAppointment')}</h1>
      <div className="testimonial-section mt-5 mb-5" id="flexmerce">
            <div className="marketplace-tile services-why-choose-us-tile">
                <h2 className="first-title-container font-orange text-center">{ t('howTo')}</h2>
                <p className='mb-4 mt-2 text-center'>{ t('seeHow')}</p>
                <hr></hr>
                <div className='row'>
                    <div className='col-sm-8'>
                        {/* <div className="video-container">
                            <video
                                controls
                                className='guide-video'
                            >
                                <source src={guideVideo} type="video/mp4" />
                                {t('browserNotSupport')}
                            </video>
                        </div> */}
                    </div>
                    <div className='col-sm-4'>
                        <div className='mt-3'>
                          <ol>
                            <li className='home-services-p-1rem text-light text-left' dangerouslySetInnerHTML={{__html: t('loginToAccount')}} />
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('setUpBusiness')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('manageTax')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('automateInvoicing')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('createAndManage')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('contactUsFor')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('bookADemo')}
                            </li>
                          </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row mt-5'>
          <div className='col'></div>
            <div className='col-sm-11 appointmentContainer'>
              <div className=''>
              <h2 className="first-title-container font-orange text-center">{ t('bookFreeAppointment')}</h2>
                <InlineWidget url="https://calendly.com/flexmerce-info" className='calendar' />
              </div>
            </div>
          <div className='col'></div>
        </div>
        <ContactSection />
      </div>
      <Footer />
    </div>
    </>
  )
}