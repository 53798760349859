import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SidebarData from './SidebarData';
import '../css/sidebar.css';
import { IconContext } from 'react-icons';
import i18next from 'i18next';
import logo from '../images/inverse-favicon.png';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t } = useTranslation();
  const [sidebar, setSidebar] = useState(false);
  const isOnBoarded = useSelector((state) => state.onboard.isOnBoarded);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const sidebarData = SidebarData();

  const showSidebar = () => setSidebar(!sidebar);

  const isPrintInvoicePage = location.pathname.startsWith('/printInvoice/');
  const isPrintMultipleInvoicePage = location.pathname.startsWith('/PrintMultipleInvoices');
  const isLoginPage = location.pathname.startsWith('/login');
  const isContactPage = location.pathname.startsWith('/contact');
  const isRootPath = location.pathname === '' || location.pathname === '/' || location.pathname === '/home';
  const currentLanguageCode = useSelector((state) => state.onlanguage.currentLanguageCode);
  
  const pageNameMap = {
    '/dashboard': t('loggedInMenu.dashboard'),
    '/invoices': t('loggedInMenu.invoices'),
    '/exportInvoices': t('loggedInMenu.exportInvoices'),
    '/jobHistories': t('loggedInMenu.history'),
    '/settings': t('loggedInMenu.settings'),
    '/manageTaxOptions/': t('loggedInMenu.manageTaxOptions'),
    '/addUpdateTaxOption/new': t('loggedInMenu.addTaxOption'),
    '/addUpdateTaxOption/edit': t('loggedInMenu.editTaxOption'),
    '/invoice/new': t('loggedInMenu.newInvoice'),
    '/invoice/edit': t('loggedInMenu.editInvoice'),
  };

  const getCurrentPageName = (pathname) => {
    if (pageNameMap[pathname]) {
      return pageNameMap[pathname];
    }
  
    if (pathname.startsWith('/addUpdateTaxOption/edit/')) {
      return t('loggedInMenu.editTaxOption');
    }

    if (pathname.startsWith('/invoice/edit/')) {
      return t('loggedInMenu.editInvoice');
    }
  
    return '';
  };

  const currentPageName = getCurrentPageName(location.pathname);

  useEffect(() => {
    i18next.changeLanguage(currentLanguageCode);
  }, [currentLanguageCode])

  return (
    <IconContext.Provider value={{ color: '#fff' }}>
      {isAuthenticated && !isPrintInvoicePage && !isPrintMultipleInvoicePage && isOnBoarded
        && !isLoginPage
        && !isContactPage
        && !isRootPath && (
          <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          <h4 className="ml-3 current-page-name">{currentPageName}</h4>
          <img src={logo} className="logo" />
          <div className="right-section">
            <h4 className="business-name">{localStorage.getItem('businessName')}</h4>
          </div>
        </div>
        
      )}
      {isAuthenticated && !isPrintInvoicePage && (
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {sidebarData.map((item, index) => (
              <li
                key={index}
                className={`${item.cName} ${location.pathname === item.path ? 'active' : ''}`}
              >
                <Link to={item.path}>
                  {item.icon}
                  <span className="text-light sideBarSpan">{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </IconContext.Provider>
  );
}

export default Navbar;