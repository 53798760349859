import React from 'react';
import '../css/footer.css';
import '../css/homepageMenu.css';
import bmlogo from '../images/bm-logo.png'
import rflogo from '../images/rf-logo.png'
import rklogo from '../images/rk-logo.png'
import fnlogo from '../images/fn-logo.png'
import cdlogo from '../images/cd-logo.png'
import sflogo from '../images/sf-logo.png'

import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

import { FaFacebookF, FaInstagram, FaTiktok, FaTwitter} from 'react-icons/fa';

export default function Footer() {
    
    const { t } = useTranslation();
    // const images = [bmlogo, rflogo, rklogo, fnlogo, cdlogo, sflogo];
    const images = [rflogo, rklogo, fnlogo, cdlogo, sflogo];

    return (
        <>
        <div className='footer pt-5 font-orange'>
            <hr></hr>
            <div className='row mt-5 mb-2 d-flex align-items-stretch'>
                <div className='col-sm-3'>
                        <h2 className='font-orange mt-4'>{t('flexMerce')}</h2>
                        <h6 className='text-light p-3 footer-subtitle'>
                            {t('partOfTopweb')}
                        </h6>
                </div>
                <div className='col-sm-3'>
                    <ul className='footer-ul'>
                        <li><a href='/' className='underline-orange-thin'>{ t('home')}</a></li>
                            <li><a href='/services' className='underline-orange-thin'>{ t('services')}</a></li>
                        <li><a href='/appointment' className='underline-orange-thin'>{ t('bookDemo')}</a></li>
                        <li><a href='/pricing' className='underline-orange-thin'>{ t('pricing')}</a></li>
                    </ul>
                </div>
                <div className='col-sm-3'>
                    <ul className='footer-ul footer-ul-2'>
                        <li><a href='/guide' className='underline-orange-thin'>{ t('guide')}</a></li>
                        <li><a href='/calculatesavings' className='underline-orange-thin'>{ t('calculateSavings')}</a></li>
                        <li><a href='/contact' className='underline-orange-thin'>{ t('contactUs')}</a></li>
                        {/* <li><a href='/blogs' className='underline-orange-thin'>{ t('blogs')}</a></li> */}
                    </ul>
                </div>
                <div className='col-sm-3'>
                    <ul className='footer-ul'>
                        <li><a href='/' className='underline-orange-thin'>help@flexmerce.com</a></li>
                        <li><a href='/services' className='underline-orange-thin'>+44 (0) 333 789 0702</a></li>
                        <li><h6 className='text-light footer-subtitle'>
                        Canary Wharf | London
                        </h6></li>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/flexmerce" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF size={30} color="#fff" />
                            </a>
                            <a href="https://x.com/flexmerce" target="_blank" rel="noopener noreferrer">
                                <FaTwitter size={30} color="#fff" />
                            </a>
                            <a href="https://www.instagram.com/flexmerce" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={30} color="#fff" />
                            </a>
                            <a href="https://www.tiktok.com/@flexmerce" target="_blank" rel="noopener noreferrer">
                                <FaTiktok size={30} color="#fff" />
                            </a>
                        </div>
                    </ul>
                </div>
            </div>
            <LanguageSelector />
            <div className="w-100 relative d-flex align-content-center">
                <div className="absolute"></div>
                <div className="logo-row relative flex justify-between items-center max-w-4xl mx-auto px-4 py-8 pr-5 pl-5 pb-2 footerContainer">
                {images.map((image, index) => (
                    <div key={index} className="w-20 h-20 flex items-center justify-center bg-white bg-opacity-10 rounded-full p-2 platformIcon">
                    <div className="relative w-16 h-16">
                        <img src={image} alt={`Logo ${index + 1}`} className="object-contain w-full h-full" loading='lazy' />
                    </div>
                    </div>
                ))}
                </div>
            </div>
            <div className="w-100 relative d-flex align-content-center footer-copyright">
                <div className="absolute"></div>
                <div className=" relative flex justify-between items-center max-w-4xl mx-auto pb-3 footerContainer">
                <a href="/terms" className='font-orange text-legal'>{ t('privacyPolicy')}</a>
                <a href="/privacy" className='font-orange ml-5 text-legal'>{ t('terms')}</a>
                </div>
            </div>
            <div className="w-100 relative d-flex align-content-center footer-copyright">
                <div className="absolute"></div>
                <div className=" relative flex justify-between items-center max-w-4xl mx-auto pb-3 footerContainer">
                        <span>{new Date().getFullYear()} { t('rightsReserved')}</span>
                </div>
            </div>
        </div>
        </>
    );
}
