import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import '../css/footer.css';
import '../css/homepageMenu.css';

import uk_flag from '../images/flags/uk.svg'
import us_flag from '../images/flags/us.svg'
import es_flag from '../images/flags/es.svg'
import fr_flag from '../images/flags/fr.svg'
import de_flag from '../images/flags/de.svg'
import it_flag from '../images/flags/it.svg'
import dk_flag from '../images/flags/dk.svg'
import pl_flag from '../images/flags/pl.svg'
import pt_flag from '../images/flags/pt.svg'
import se_flag from '../images/flags/se.svg'
import sk_flag from '../images/flags/sk.svg'
import nl_flag from '../images/flags/nl.svg'

import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../redux/slices/languageSlice';
import { useNavigate, useLocation } from 'react-router-dom'

export default function LanguageSelector() {

    const currentLanguageCode = useSelector((state) => state.onlanguage.currentLanguageCode);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const languageOptions = [
        { key: 'en', value: 'en', flag: uk_flag, text: 'UK English' },
        { key: 'nl', value: 'nl', flag: nl_flag, text: 'Nederlands' },
        { key: 'de', value: 'de', flag: de_flag, text: 'Deutsch' },
        { key: 'fr', value: 'fr', flag: fr_flag, text: 'Français' },
        { key: 'it', value: 'it', flag: it_flag, text: 'Italiano' },
        { key: 'us', value: 'us', flag: us_flag, text: 'US English' },
        { key: 'es', value: 'es', flag: es_flag, text: 'Español' },
        { key: 'dk', value: 'dk', flag: dk_flag, text: 'Dansk' },
        { key: 'pl', value: 'pl', flag: pl_flag, text: 'Polski' },
        { key: 'pt', value: 'pt', flag: pt_flag, text: 'Português' },
        { key: 'se', value: 'se', flag: se_flag, text: 'Svenska' },
        { key: 'sk', value: 'sk', flag: sk_flag, text: 'Slovenčina' },
        
    ];

    // Move handleLanguageChange here
    const handleLanguageChange = (e, { value }) => {
        const currentPath = location.pathname;
        const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}/, '');
        const newPath = `/${value}${pathWithoutLang || '/'}`;
        dispatch(setLanguage(value));
        i18next.changeLanguage(value);
        navigate(newPath);
    };

    return (
        <>
            <Dropdown
                className='language-dropdown'
                fluid
                selection
                options={languageOptions.map((option) => ({
                    key: option.key,
                    value: option.value,
                    text: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={option.flag}
                                alt={option.text}
                                style={{ width: '20px', height: '15px', marginRight: '8px' }}
                            />
                            {option.text}
                        </div>
                    ),
                }))}
                value={currentLanguageCode}
                onChange={handleLanguageChange}
                placeholder="Select Language"
                icon={null} /* Hides the default icon */
                trigger={(
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <span>
                            <img
                                src={languageOptions.find((option) => option.value === currentLanguageCode)?.flag}
                                alt="Selected flag"
                                style={{ width: '40px', height: '15px', marginRight: '8px' }}
                            />
                            {languageOptions.find((option) => option.value === currentLanguageCode)?.text}
                        </span>
                        <span style={{ marginLeft: 'auto' }}>&#9662;</span>
                    </div>
                )}
                zIndex={1000}                
            />
        </>
    );
}