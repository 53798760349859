
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import store from './redux/store/store';
import { setLanguage } from './redux/slices/languageSlice';

const detectLanguage = () => {
  const browserLanguage = navigator.language || navigator.userLanguage || 'en';
  return browserLanguage.split('-')[0];
};

const initializeLanguage = () => {
  const storedLanguage = localStorage.getItem('i18nextLng'); // Key used by i18n
  const browserLanguage = detectLanguage();

  return storedLanguage || browserLanguage || 'en';
};

const selectedLanguage = initializeLanguage();
store.dispatch(setLanguage(selectedLanguage));
localStorage.setItem('i18nextLng', selectedLanguage);

i18n
  .use(Backend)
  .use(LanguageDetector) 
  .use(initReactI18next)
  .init({
    lng: selectedLanguage,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    }
  });

export default i18n;