import React from "react";
import "../css/onBoarding.css";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const OnboardingModal = ({ isVisible, onClose }) => {
    const integrationSetup = useSelector((state) => state.onIntegrationSlice.isIntegrationSetup);
    const isTemplateSetup = useSelector((state) => state.onInvoiceTemplateSlice.isTemplateSetup);
    const isProfileSetup = useSelector((state) => state.onProfile.isProfileSetup);
    const { t } = useTranslation();
      if (!isVisible)
      return
  let steps = [
    {
        title: t('onBoarding.verifyAccount'),
        description: t('onBoarding.accountVerified'),
        icon: "🔒",
        time: "",
        completed: true, // Mark this step as completed
        url: '',
    },
    {
        title: t('onBoarding.setupProfile'),
        description: t('onBoarding.updateBusinessDetails'),
        icon: "👨‍💼",
        time: "",
        completed: isProfileSetup,
        url: '/settings/businessDetails'
    },
    {
        title: t('onBoarding.invoiceTempDetails'),
        description: t('onBoarding.invoiceDetails'),
        icon: "📋",
        time: "",
        completed: isTemplateSetup,
        url: '/settings/invoiceTemplate'
    },
    {
        title: t('onBoarding.bmAndRfAcc'),
        description: t('onBoarding.integrateBmAndRf'),
        icon: "🔗",
        time: "",
        completed: integrationSetup,
        url: '/settings/integrations'
    },
    // {
    //     title: "Check Sales Tax Setup",
    //     description: "Customize your tax settings to match your business requirements.",
    //     icon: "📊",
    //     time: "",
    //     completed: false,
    //     url: '/addUpdateTaxOption/new',
    // },
];

    return (
      <div className="modal-overlay">
        <div className="onboarding-modal">
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
          <header className="onboarding-header">
            <h1 className="text-center">{t('onBoarding.welcomeToFlexmerce')}</h1>
            <p className="text-center">{t('onBoarding.completeQuickSteps')}</p>
          </header>
          <div className="onboarding-steps">
                    {steps.map((step, index) => (
                      <a
                        href={step.url || "#"}
                        onClick={(e) => {

                          if (!step.url) e.preventDefault();
                        }}
                        target={step.url ? "_blank" : "_self"}
                        rel="noopener noreferrer"
                        className={`onboarding-card ${step.completed ? "completed" : ""} ${!step.url ? "disabled" : ""} ${"text-decoration-none"}`}
                        key={index}>
                        <div className="onboarding-icon">{step.icon}</div>
                        <div className="onboarding-content">
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                                <span className="onboarding-time">{step.time}</span>
                            </div>
                            {step.completed && (
                                <div className="onboarding-status">
                                    <span className="success-icon">✔️</span>
                                </div>
                            )}
                        </a>
                    ))}
          </div>
        </div>
      </div>
    );
};
  
export default OnboardingModal;
